import React from "react";
import { FaFacebook, FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";
import { Link } from "react-scroll";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-[80px] ">
      <div className="container m-auto flex flex-wrap justify-between">
        <div className="w-full md:w-1/2 lg:w-1/4 p-4">
          <h2 className="text-2xl font-bold mb-4">Yusuf YMC Agency </h2>
          <p className="mb-4">
            We are a creative web app agency that builds amazing digital
            experiences for our clients.
          </p>
          <p>&copy; {new Date().getFullYear()} Yusuf Ymc Agency</p>
        </div>

        <div className="w-full md:w-1/2 lg:w-1/4 p-4">
          {/* <h3 className="text-lg font-bold mb-4">Quick Links</h3> */}
          <ul className="flex flex-col space-y-2 text-lg">
            <li>
              <Link to="home" smooth={true} duration={500}>
                Home
              </Link>
            </li>
            <li>
              <Link to="about" smooth={true} duration={500}>
                About
              </Link>
            </li>
            <li>
              <Link to="skills" smooth={true} duration={500}>
                Skills
              </Link>
            </li>
            <li>
              <Link to="work" smooth={true} duration={500}>
                Work
              </Link>
            </li>
            <li>
              <Link to="contact" smooth={true} duration={500}>
                Contact
              </Link>
            </li>
          </ul>
        </div>

        <div className="w-full md:w-1/2 lg:w-1/4 p-4">
          <h3 className="text-lg font-bold mb-4">Address</h3>
          <p className="mb-2">Um mugulina near palace hotel</p>
          <p className="mb-2">Qatar - Doha</p>
          <p className="mb-2">yuusufmahud@gmail.com or + 974 77237879</p>
        </div>

        <div className="w-full md:w-1/2 lg:w-1/4 p-4">
          <h3 className="text-lg font-bold mb-4">Connect With </h3>
          <ul className="flex items-center space-x-4">
            <li>
              <a href="https://www.linkedin.com/in/yusufymc/">
                <FaLinkedin size={32} />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/YustoYmc">
                <FaTwitter size={32} />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/profile.php?id=100004725063382">
                <FaFacebook size={32} />
              </a>
            </li>
            <li className="">
              <a className="" href="https://github.com/ymc4766">
                <FaGithub size={32} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
