import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";

const Home = () => {
  const scrollDown = () => {
    const el = document.querySelector("#work");
    window.scrollTo({
      top: el?.getBoundingClientRect().top,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <div name="home" className="w-full h-screen bg-[#0a192f]">
      {/* Container */}
      <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full">
        <p className="text-pink-600">Hi, my name is</p>
        <h1 className="text-4xl sm:text-7xl font-bold text-[#ccd6f6]">
          Yusuf Mohamud Ali
        </h1>
        <h2 className="text-4xl sm:text-7xl font-bold text-[#8892b0]">
          Full Stack Developer.
        </h2>
        <p className="text-[#8892b0] text-2xl py-4 max-w-[700px]">
          I’m a full-stack Software developer specializing in building
          exceptional Full Syack Web Applications , With beautifull UI and UX
          experiences. Currently, I’m focused on building Progressive full-stack
          web applications. and Vey Passionate About ML AND Artificial
          intelligence , YMC is AI enthusiast integrating AI technologies in to
          web applications ...
        </p>
        <div>
          <button
            to="work"
            onClick={scrollDown}
            className="text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-pink-600 hover:border-pink-600"
          >
            Check Down
            <span className="group-hover:rotate-90 duration-300">
              <HiArrowNarrowRight className="ml-3 " />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
