import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

const EmailScreen = () => {
  const form = useRef();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_angzsvq",
        "template_tne7n74",
        form.current,
        "wCaOnG_qZRsZa0Z3i"
      )
      .then(
        (result) => {
          if (result.status === "OK") {
            toast.success("Sent Message Succesfull");
          }
        },
        (error) => {
          toast.error(error.text);
        }
      );
    toast.success(
      "Your message has been successfully sent.i will contact you soon.",
      { autoClose: 8000 }
    );
  };
  return (
    <div
      className="pa-6 max-w-[800px] m-auto bg-slate-100 py-6 md:my-4 "
      name="contact"
    >
      <h1 className="text-4xl text-bold text-center sm:px-4 md:px-2 py-5 ">
        Send Me Message{" "}
      </h1>
      <form class="max-w-sm mx-auto" ref={form} onSubmit={sendEmail}>
        <div class="mb-5">
          <label
            for="email"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Your Name
          </label>
          <input
            type="text"
            id="name"
            name="from_name"
            placeholder="Your Name"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
          />
        </div>
        <div class="mb-5">
          <label
            for="email"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Your Email
          </label>
          <input
            type="email"
            name="from_email"
            placeholder="Enter your Email"
            id="email"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
          />
        </div>
        <div class="mb-5">
          <label
            for="large-input"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Message
          </label>
          <input
            type="text"
            id="large-input"
            name="message"
            required
            className="block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>

        <button
          value="send"
          type="submit"
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default EmailScreen;

{
  /* <form ref={form} onSubmit={sendEmail}>
  <label>Name</label>
  <input type="text" name="from_name" />
  <label>Email</label>
  <input type="email" name="from_email" />
  <label>Message</label>
  <textarea name="message" />
  <input type="submit" value="Send" />
</form>; */
}
