import React from "react";

const GetClient = () => {
  return (
    <div className="max-w-[840px] m-auto  py-10 md:py-12 w-full  content-center px-6 md:px-8 ">
      <div className="flex flex-col space-y-4  lg:flex-row  items-center justify-between w-full">
        <div className="flex flex-col  space-y-4">
          <h3 className="sm:tetx-2xl  md:text-4xl font-bold">
            Get A beautiful Web Application That Wins Customers
          </h3>

          <p className="text-xl font-semibold">
            Elevate your business with a trusted website that your customers
            will love. Our expert web developers will represent your brand in
            the best possible way. Your website will load extremely fast and
            look great on all devices.
          </p>
        </div>
        <div>
          <img
            alt="/"
            className="w-[1700px] ml-4"
            src="https://images.pexels.com/photos/3803517/pexels-photo-3803517.jpeg?auto=compress&cs=tinysrgb&w=600"
          />
        </div>
      </div>
    </div>
  );
};

export default GetClient;
