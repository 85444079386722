import React, { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import Header from "./Header/Header";
import About from "./components/About";
// import Avatar from "./components/Avatar";
import Footer from "./components/Footer";
import GetClient from "./components/GetClient";
import Hero from "./components/Hero";
import Home from "./components/Home";
import Memory from "./components/Memory";
import Newslatter from "./components/NewsLatter";
import Skills from "./components/Skills";
import Work from "./components/Work";
import { hero, memory } from "./components/data";
import EmailScreen from "./utils/EmailScreen";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Loading() {
  return (
    <div className="flex justify-center items-center h-screen">
      <ClipLoader size={150} aria-label="Loading Spinner" color="#00ff" />
    </div>
  );
}

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay for demonstration purposes
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, []);
  return (
    <div className="App">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Header />
          <Home />
          {/* <Hero hero={hero} /> */}
          {/* <Avatar /> */}
          <About />
          <Work />

          <GetClient />
          <EmailScreen />

          <Skills />
          {/* <Memory memory={memory} /> */}
          {/* <Newslatter /> */}
          <Footer />

          <ToastContainer />
        </>
      )}
    </div>
  );
}

export default App;
