import React from "react";
import { CSSTransition } from "react-transition-group";
import "./About.css"; // Create a CSS file for your animations

const About = () => {
  const animateDuration = 500; // Set the animation duration in milliseconds

  const slides = [
    {
      id: 1,
      title: "my  Mission",
      content: "committed to helping businesses thrive in the digital world.",
      image:
        "https://images.pexels.com/photos/4126724/pexels-photo-4126724.jpeg?auto=compress&cs=tinysrgb&w=600",
    },
    {
      id: 2,
      title: "YMC Vision",
      content:
        " AI , Computer vision Neural Network , FUll Stackk Devs , and creativity.",
      image:
        "https://images.pexels.com/photos/3184418/pexels-photo-3184418.jpeg?auto=compress&cs=tinysrgb&w=600",
    },
    {
      id: 3,
      title: "About YMC",
      image:
        "https://images.pexels.com/photos/6248957/pexels-photo-6248957.jpeg?auto=compress&cs=tinysrgb&w=600",
      content:
        "this  web app agency dedicated to crafting exceptional digital experiences.",
    },
  ];

  return (
    <div id="about" name="about" className="bg-gray-100 py-16">
      <div className="container mx-auto">
        <h1 className="text-4xl font-extrabold text-gray-800 text-center mb-4">
          YMC VISION
        </h1>
        <div className="flex flex-col sm:flex-row">
          {slides.map((slide) => (
            <CSSTransition
              key={slide.id}
              in={true}
              appear
              timeout={animateDuration}
              classNames="slide"
            >
              <div className="w-full sm:w-1/3 p-4">
                <h2 className="text-2xl font-bold text-gray-800">
                  {slide.title}
                </h2>
                <p className="text-gray-600 mt-4">{slide.content}</p>
                {slide.image && (
                  <img
                    src={slide.image}
                    alt={slide.title}
                    className="mt-4 rounded-lg w-full h-auto"
                  />
                )}
              </div>
            </CSSTransition>
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;
